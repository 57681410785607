.btn {
    display: inline-block;
    background: none;
    border: 0;
    border-radius: $radius--default;
    font: bold 13px $font--primary;
    height: $height--button;
    line-height: $height--button - 2;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .15em;
    margin: 0;
    padding: 0 50px;
    outline: none;
    cursor: pointer;
    transform: none;
    transition: background .15s ease-in-out, color .25s ease-in-out, transform .25s ease;

    &:disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    &:active {
        transform: translateY(2px);
    }

    &--primary {
        background: $color--primary;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, .25);
        color: white;

        &:hover {
            background: darken($color--primary, 5%);
            color: lighten($color--primary, 80%);
        }
    }

    &--secondary {
        background: $color--secondary;
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, .25);
        color: $color--text-light;

        &:hover {
            background: darken($color--secondary, 5%);
            color: #d4ccb1;
        }
    }
}
