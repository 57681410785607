.form {
    display: block;
    margin: 2em 5em;

    &__messages {
        display: block;
        margin: .5em 0 2.5em;
    }

    &__fields {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__row {
        flex-basis: 47%;
        display: block;
        margin-bottom: 1em;
        text-align: left;

        &--full {
            flex-basis: 100%;
        }

        input[type=text],
        textarea {
            width: 100%;
            height: $height--input;
            background: white;
            border: 1px solid #eee;
            border-bottom: 2px solid #ddd;
            border-radius: $radius--tiny;
            font: normal 15px $font--primary;
            padding-left: 1em;
            padding-right: 1em;
            color: $color--text;
            outline: none;
            transition: background .5s ease-in-out;

            &:focus {
                border-color: $color--secondary;
            }
        }

        textarea {
            min-height: 100px;
            resize: none;
            padding-top: 1em;
            padding-bottom: 1em;
        }

        select {
            width: 100%;
            height: $height--input;
            background: white;
            border: 1px solid #eee;
            border-bottom: 2px solid #ddd;
            font: normal 15px $font--primary;
            outline: none;
        }

        &.is-hidden {
            display: none;
        }

        &.has-error {
            input[type=text],
            textarea {
                color: $color--danger;
                border-color: $color--danger;
            }
        }
    }

    &__label {
        font: bold 12px/2.5 $font--primary;
        letter-spacing: .125em;
        text-transform: uppercase;

        sup {
            color: $color--danger;
        }
    }

    &__submit {
        display: block;
        margin-top: 1em;
    }
}

@media #{$mq--tablet} {
    .form {
        margin-left: 0;
        margin-right: 0;
    }
}

@media #{$mq--mobile} {
    .form {
        &__fields {
            display: block;
        }

        &__submit {
            .btn {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
