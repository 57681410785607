.services {
    &__grid {
        p {
            font-size: 22px;
            font-weight: bold;
            color: $color--text-light;
            text-transform: uppercase;
        }

        ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                flex-basis: 50%;
                padding: 5px 0;

                &:before {
                    content: '\f00c';
                    font-family: 'FontAwesome';
                    margin-right: 8px;
                    color: lighten($color--secondary, 50%);
                }
            }
        }
    }
}

@media #{$mq--mobile} {
    .services {
        &__grid {
            ul {
                display: block;
            }

            p {
                font-size: 16px;
            }
        }
    }
}
