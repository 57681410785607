body {
    background: $color--secondary;
    font: normal 15px $font--primary;
    color: $color--text;
    text-align: center;
    overflow-y: scroll;
}

.wrapper {
    width: $width--container;
    margin: 0 auto;
}

.site-container {
    display: block;
    margin-top: 40px;
    margin-bottom: 30px;
    background: white;
    border-radius: $radius--tiny;
    box-shadow: 0 0 30px rgba(0, 0, 0, .3);
}

@media #{$mq--desktop} {
    .wrapper {
        width: 100%;
        padding: 0 20px;
    }
}

@media #{$mq--mobile} {
    .wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .site-container {
        width: 100%;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }
}
