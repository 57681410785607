.page {
    &__title {
        display: block;
        font-weight: bold;
    }

    &__lead {
        text-align: left;

        p, div {
            margin-bottom: 1em;
        }
    }
}
