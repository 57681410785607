.site-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 30px;
    color: white;

    &__legal {
        font-size: 12px;

        a {
            color: white;
            text-decoration: underline;
        }
    }

    &__menu {
        li {
            font-size: 12px;
            font-weight: bold;
            display: inline-block;
            margin-right: 15px;

            a {
                color: white;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media #{$mq--mobile} {
    .site-footer {
        padding: 15px 0;
    }
}
