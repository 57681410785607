.herospace {
    display: block;
    height: 400px;
    background: url(../img/herospace.jpg) center -200px no-repeat #4e4e4e;
    background-size: cover;
    background-attachment: fixed;
}

@media #{$mq--tablet-portrait} {
    .herospace {
        height: 250px;
    }
}

@media #{$mq--mobile} {
    .herospace {
        background-attachment: initial;
        background-size: 120%;
        background-position: center top;
    }
}

@media #{$mq--mobile-portrait} {
    .herospace {
        height: 200px;
        background-size: 135%;
    }
}
