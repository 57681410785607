.about {
    &__grid {
        margin-top: 50px;
        text-align: center;

        p {
            font-size: 22px;
            font-weight: bold;
            color: $color--text-light;
            text-transform: uppercase;
        }

        ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                flex-basis: 45%;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: $radius--tiny;
                padding: 20px 90px;
                margin: 20px 2.5%;

                i.fa {
                    font-size: 60px;
                    margin-bottom: 20px;
                    color: $color--primary;
                }

                h2 {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }
}

@media #{$mq--tablet-portrait} {
    .about {
        &__grid {
            ul {
                li {
                    padding: 20px;
                }
            }
        }
    }
}

@media #{$mq--mobile} {
    .about {
        &__grid {
            ul {
                display: block;

                li {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 0;
                    margin: 5px 0;
                    padding: 10px 0;
                    text-align: left;

                    i.fa {
                        flex-basis: 60px;
                        font-size: 42px;
                        margin: 0;
                    }

                    h2 {
                        flex: 1;
                    }
                }
            }

            p {
                font-size: 16px;
            }
        }
    }
}
