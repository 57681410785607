.site-header {
    position: relative;
    z-index: 100;
    background: white;
    border-radius: $radius--tiny $radius--tiny 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    transition: padding .25s ease-in-out;

    &__logo {
        width: 115px;
        height: 115px;
        position: relative;
        top: 15px;
        background: url(../img/logo.png) no-repeat;
        background-size: 100%;
    }

    &__menu {
        display: block;
        list-style: none;

        li {
            display: inline-block;
            font: normal 15px $font--primary;
            margin: 0 15px;
            text-transform: uppercase;

            a {
                color: $color--text;

                &:hover {
                    color: $color--text-dark;
                }
            }
        }
    }

    &__toggler {
        width: 20px;
        height: 20px;
        display: none;
        position: absolute;
        top: 50%;
        right: 20px;
        z-index: 20;
        cursor: pointer;
        margin-top: -10px;
        background: url(../img/icon-menu.svg) no-repeat;
        background-size: 20px;
        transition: background .25s ease-in-out;
        
        &.is-open {
            background: url(../img/icon-close.svg) no-repeat;
        }
    }

    &.is-sticky {
        width: $width--container;
        position: fixed;
        top: 0;
        left: 50%;
        padding: 10px 0;
        border-radius: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, .3);
        margin-left: -#{$width--container / 2};

        .site-header__logo {
            width: 40px;
            height: 40px;
            top: 0;
            left: 15px;
        }

        .site-header__menu {
            width: 100%;
            text-align: right;
            padding-right: 10px;
        }
    }
}

@media #{$mq--desktop} {
    .site-header {
        &.is-sticky {
            width: 100%;
            left: 0;
            margin-left: 0;
        }
    }
}

@media #{$mq--mobile} {
    .site-header {
        padding: 0 10px;

        &__logo {
            width: 80px;
            height: 80px;
            top: 0;
        }

        &__menu {
            display: none;
            background: rgba(255, 255, 255, .95);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            width: 100%;
            height: auto;
            margin: 0;
            padding: 80px 20px 20px;
            box-shadow: 0 0 30px rgba(0, 0, 0, .2);

            li {
                display: block;
                width: 100%;
                margin: 0;
                border-bottom: 1px solid #eee;
                padding: 15px 0;
                font-size: 20px;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        &__toggler {
            display: block;
        }

        &.is-sticky {
            .site-header__menu {
                text-align: center;
            }
        }
    }
}