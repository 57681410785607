.section {
    display: block;
    padding: 50px 100px;
    margin-bottom: 50px;
    text-align: left;

    &:nth-of-type(even) {
        background: #f5f5f5;
        margin-bottom: 100px;
    }

    &:last-child {
        margin-bottom: 0;
        border-radius: 0 0 $radius--tiny $radius--tiny;
    }

    &__title {
        display: block;
        position: relative;
        font: normal 42px/1.3 $font--secondary;
        letter-spacing: -0.1em;
        color: $color--primary;
        margin-bottom: .5em;

        &:before {
            content: '';
            width: 15px;
            height: 15px;
            display: block;
            position: absolute;
            top: 50%;
            left: -40px;
            margin-top: -7px;
            background: $color--primary;
            border-radius: $radius--tiny / 2;
        }
    }

    &__lead {
        p, div {
            font-size: 16px;
            line-height: 1.7;
            margin-bottom: 1em;
        }

        a:not(.btn) {
            color: $color--primary;
            text-decoration: underline;

            &:hover {
                color: lighten($color--primary, 10%);
            }
        }
    }
}

@media #{$mq--tablet} {
    .section {
        padding-left: 50px;
        padding-right: 50px;

        &__title {
            &:before {
                left: -30px;
            }
        }
    }
}

@media #{$mq--tablet-portrait} {
    .section {
        margin-bottom: 30px;

        &:nth-of-type(even) {
            margin-bottom: 50px;
        }
    }
}

@media #{$mq--mobile} {
    .section {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        &__title {
            font-size: 32px;
        }

        &__lead {
            p, div {
                font-size: 14px;
            }
        }
    }
}
