// Colors
$color--primary: #dc4433;
$color--secondary: #434a55;
$color--accent: #a8c458;

$color--text: #3e3e3e;
$color--text-light: #696f79;
$color--text-dark: #111;

$color--success: #73a338;
$color--danger: #c11d1d;

// Dimensions
$width--container: 1100px;
$height--button: 52px;
$height--input: 36px;
$radius--default: 8px;
$radius--tiny: 4px;

// Fonts
$font--primary: 'Roboto Condensed', sans-serif;
$font--secondary: 'Nova Square', serif;

// Media query breakpoints
$mq--desktop: 'screen and (max-width: 1140px)';
$mq--tablet: 'screen and (max-width: 1024px)';
$mq--tablet-portrait: 'screen and (max-width: 768px)';
$mq--mobile: 'screen and (max-width: 640px)';
$mq--mobile-portrait: 'screen and (max-width: 400px)';
