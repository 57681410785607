.alert {
    display: block;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    background: white;
    border: 2px solid transparent;
    border-radius: $radius--tiny;
    padding: 10px;
    margin-bottom: 1em;

    &--success {
        color: $color--success;
        border-color: $color--success;
    }

    &--danger {
        color: $color--danger;
        border-color: $color--danger;
    }
}
