.selector {
    width: 640px;
    margin: 150px auto 50px;
    background: white;
    border-radius: $radius--tiny;
    box-shadow: 0 0 30px rgba(0, 0, 0, .2);

    &__title {
        display: block;
        position: relative;
        top: -35px;

        img {
            width: 140px;
            height: 140px;
        }

        p {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            color: $color--secondary;
            margin-top: 10px;
        }
    }

    &__fields {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 48%;
            height: 360px;
            border-radius: $radius--default;
            overflow: hidden;
            text-align: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 1%;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 10;
                background: rgba(0, 0, 0, .5);
                transition: background .5s ease-in-out;
            }

            &:hover {
                &:before {
                    background: rgba(0, 0, 0, .25);
                }
            }

            h2 {
                position: relative;
                z-index: 20;
                font-size: 24px;
                font-weight: bold;
                text-transform: uppercase;
                color: white;
                text-shadow: 1px 2px rgba(0, 0, 0, .35);
            }

            &:nth-child(1) {
                margin-left: 1%;
                background: url(../img/selector-1.jpg);
            }

            &:nth-child(2) {
                margin-right: 1%;
                background: url(../img/selector-2.jpg);
            }
        }
    }
}

@media #{$mq--mobile} {
    .selector {
        width: 95%;
        overflow: hidden;
        margin: 60px auto 20px;

        &__title {
            top: 10px;

            p {
                font-size: 15px;
                margin-bottom: 20px;
            }
        }

        &__fields {
            display: block;
            margin-bottom: 10px;

            a {
                height: 120px;
                margin: 10px;

                &:nth-child(1),
                &:nth-child(2) {
                    margin: 10px;
                }
            }
        }
    }
}
